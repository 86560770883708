var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.is_loading,"variant":"transparent","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Добавить пост в базу знаний ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок поста")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.header),callback:function ($$v) {_vm.$set(_vm.form, "header", $$v)},expression:"form.header"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',{class:{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок поста (en)")]),_c('b-form-input',{attrs:{"id":"name_en"},model:{value:(_vm.form.header_en),callback:function ($$v) {_vm.$set(_vm.form, "header_en", $$v)},expression:"form.header_en"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',{class:{
                              'text-muted' : errors.length > 0 ? false : true,
                              'font-weight-bolder': errors.length > 0 ? true : false,
                              'text-danger' : errors.length > 0 ? true : false
                          },staticStyle:{"font-size":"12px"}},[_vm._v("Human Friendly URL")]),_c('b-form-input',{attrs:{"id":"slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('span',{class:{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    },staticStyle:{"font-size":"12px"}},[_vm._v("Категория")]),_c('v-select',{attrs:{"options":_vm.categories,"label":"title","clearable":false,"placeholder":"Выбрать категорию"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',{class:{
                              'text-muted' : errors.length > 0 ? false : true,
                              'font-weight-bolder': errors.length > 0 ? true : false,
                              'text-danger' : errors.length > 0 ? true : false
                          },staticStyle:{"font-size":"12px"}},[_vm._v("Время на прочтение")]),_c('b-form-input',{attrs:{"id":"time_read"},model:{value:(_vm.form.time_read),callback:function ($$v) {_vm.$set(_vm.form, "time_read", $$v)},expression:"form.time_read"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('span',{class:{
                              'text-muted' : errors.length > 0 ? false : true,
                              'font-weight-bolder': errors.length > 0 ? true : false,
                              'text-danger' : errors.length > 0 ? true : false
                          },staticStyle:{"font-size":"12px"}},[_vm._v("Время на прочтение (en)")]),_c('b-form-input',{attrs:{"id":"time_read_en"},model:{value:(_vm.form.time_read_en),callback:function ($$v) {_vm.$set(_vm.form, "time_read_en", $$v)},expression:"form.time_read_en"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('span',{class:{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    },staticStyle:{"font-size":"12px"}},[_vm._v("Описание")]),_c('quill-editor',{staticClass:"form-control font-weight-normal p-0",staticStyle:{"height":"auto"},attrs:{"id":"content","options":_vm.snowOption},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}])})],1)]),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"form-group-with-label"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',{class:{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    },staticStyle:{"font-size":"12px"}},[_vm._v("Описание (en)")]),_c('quill-editor',{staticClass:"form-control font-weight-normal p-0",staticStyle:{"height":"auto"},attrs:{"id":"content_en","options":_vm.snowOption},model:{value:(_vm.form.content_en),callback:function ($$v) {_vm.$set(_vm.form, "content_en", $$v)},expression:"form.content_en"}})]}}])})],1)])],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Опубликовать")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }