<template>
  <b-overlay
    :show="is_loading"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Добавить пост в базу знаний
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="form">
          <b-form>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Заголовок поста</span
                    >
                    <b-form-input id="name" v-model="form.header" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Заголовок поста (en)</span
                    >
                    <b-form-input id="name_en" v-model="form.header_en" />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="6" lg="6" >
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Human Friendly URL</span>                             
                            <b-form-input
                                id="slug"
                                v-model="form.slug"
                            />
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Категория</span
                    >
                    <v-select
                      :options="categories"
                      v-model="form.category"
                      label="title"
                      :clearable="false"
                      placeholder="Выбрать категорию"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols='12' md='6' lg='6'>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Время на прочтение</span>                             
                            <b-form-input
                                id="time_read"
                                v-model="form.time_read"
                            />
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols='12' md='6' lg='6'>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Время на прочтение (en)</span>                             
                            <b-form-input
                                id="time_read_en"
                                v-model="form.time_read_en"
                            />
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <div class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Описание</span
                    >
                    <quill-editor
                      id="content"
                      v-model="form.content"
                      class="form-control font-weight-normal p-0"
                      style="height: auto;"
                      :options="snowOption"
                    />
                  </validation-provider>
                </div>
              </b-col>

             <b-col cols="12" md="6" lg="6">
                <div class="form-group-with-label">
                  <validation-provider #default="{ errors }">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px;"
                      >Описание (en)</span
                    >
                    <quill-editor
                      id="content_en"
                      v-model="form.content_en"
                      class="form-control font-weight-normal p-0"
                      style="height: auto;"
                      :options="snowOption"
                    />
                  </validation-provider>
                </div>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end">
              <!-- <b-button variant="gray" class="mr-1">Предпросмотр</b-button> -->
              <b-button @click="submit" variant="primary"
                >Опубликовать</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.bubble.css";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import slugify from '@sindresorhus/slugify';

export default {
  data() {
    return {
      required,
      form: {
        header: null,
        header_en: null,
        category: {},
        content: null,
        content_en: null,
        slug: null,
        time_read: null,
        time_read_en: null,
      },
      title: "",
      editor: null,
      is_loading: false,

      snowOption: {
        theme: "bubble",
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["align", "list", "blockquote", "code-block"],
            ["link", "image"],
          ],
        },
      },
    };
  },
  props: {
    categories: Array
  },
        
  methods: {
    categoriesList() {
        this.$request.get("knowledge/categories").then((rsp) => {
        this.categories = rsp;
      });
    },
    submit(args) {
    
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$request
            .post("knowledge/add", {
              header: this.form.header,
              header_en: this.form.header_en,
              category: this.form.category.id,
              slug: this.form.slug,
              content: this.form.content,
              content_en: this.form.content_en,
              time_read: this.form.time_read,
              time_read_en: this.form.time_read_en
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `База знаний успешно пополнена`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
              this.$router.replace({ name: 'admin-knowledge-list' })
            });
        }
      });
    },

  },

  components: {
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    "form.header" : function( val ) {
        this.form.slug = slugify( val );
    }
  },
  mounted() {
    if (this.categories.length === 0) this.$router.replace({ name: 'admin-knowledge-categories' })
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.form-control .ql-editor {
  padding: 0;
}

.ql-tooltip {
  z-index: 9999;
}

[dir] .form-group-with-label .vs__dropdown-toggle .vs__selected {
  background-color: transparent !important;
  padding-right: 5px !important;
}
</style>
